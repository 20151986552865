import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../change-start-date/DatePickerCustomStyles.css";
import { BiCalendar } from "react-icons/bi";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import moment from "moment";
import Axios from "../../../service/Axios";

const ChangeStartDate = () => {
  const [searchedUser, setSearchedUser] = useState("+62");
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const [userFound, setUserFound] = useState(false);
  const [phone, setPhone] = useState("+62");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [newStartDate, setNewStartDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    setShowAlert({ type: "", message: "" });
    setUserFound(false);

    try {
      const url = `/v1/users/query/${searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { phone, name, startDate } = response.data;
        setPhone(phone);
        setShowAlert({
          type: "success",
          message: "User Found.",
          data: `${name} [ Start Date on: ${moment(startDate).format(
            "YYYY-MM-DD"
          )} ]`,
        });
        setIsLocked(!isLocked);
        setUserFound(true);
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
        setUserFound(false);
      }
    } catch (error) {
      setShowAlert({ type: "error", message: "User not found." });
      setUserFound(false);
    }
  };

  const handleUnlock = () => {
    setIsLocked(false);
  };

  const handleStartDateChange = async (date) => {
    if (!date) {
      setShowAlert({
        type: "error",
        message: "Please select a new start date.",
      });
      return;
    }

    setIsSubmitting(true);

    // Format date using moment.js
    const startDateString = moment(date).format("YYYY-MM-DD");

    try {
      if (userFound) {
        const response = await Axios.put(
          `/v1/update-startdate/${phone}`,
          {
            newStartDate: startDateString,
          },
        );
        if (response.status === 200) {
          setShowAlert({
            type: "success",
            message: "Start date updated successfully.",
          });
          setSearchedUser("+62");
          setNewStartDate(null);
          setIsLocked(false);
        } else {
          setShowAlert({ type: "error", message: "Something went wrong" });
        }
      } else {
        setShowAlert({ type: "error", message: "User not found." });
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message: "An error occurred. Please ensure that the input is correct and that the rules are followed.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const changeColorOnHover = isLocked || isLoading ? "" : "hover:bg-yellow-600";

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "" });
      }, 20000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  return (
    <div className='container mx-auto p-4'>
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className='p-6 mx-10 bg-white shadow-lg rounded-lg'>
        <h2
          className='text-2xl font-semibold mb-11 mt-6 mx-4'
          style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
          [ Change Start Date ]
        </h2>

        <div className='mb-4 mx-4 flex items-center'>
          <label
            htmlFor='searchedUser'
            className='block text-lg mr-5 text-slate-500'>
            Phone:
          </label>
          <input
            type='text'
            id='searchedUser'
            placeholder='Enter phone number'
            value={searchedUser}
            onChange={(e) => setSearchedUser(e.target.value)}
            className='w-full py-3 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
            disabled={isLocked || isSubmitting}
          />
          <button
            onClick={userFound && isLocked ? handleUnlock : handleSearch}
            className={`mr-2 px-4 ml-2 py-3 bg-gray-600 text-white rounded-lg focus:outline-none focus:ring focus:border-blue-500 ${changeColorOnHover}`}
            disabled={isSubmitting}>
            {isLocked ? "Unlock" : "Search"}{" "}
            {/* Change button text based on isLocked */}
          </button>
        </div>
        <form>
          <div className='flex items-center mb-10 pt-10 mx-4'>
            <label
              htmlFor='newStartDate'
              className='block text-lg mr-5 text-slate-500'>
              New Start Date:
            </label>
            <div className='flex items-center'>
              <DatePicker
                selected={newStartDate}
                onChange={(date) => setNewStartDate(date)}
                className='w-full border rounded-lg p-2 text-xl'
                dateFormat='yyyy-MM-dd'
                withPortal
                showYearDropdown
                yearDropdownItemNumber={10}
                customInput={
                  <input
                    type='text'
                    id='newStartDate'
                    value={
                      newStartDate
                        ? moment(newStartDate).format("YYYY-MM-DD")
                        : ""
                    }
                    onClick={() => setShowDatePicker(true)}
                    readOnly={isLocked}
                    className='w-full py-3 px-4 border rounded-lg text-xl focus:outline-none focus:ring focus:border-blue-300 cursor-pointer pl-10'
                  />
                }
              />
              <BiCalendar
                className='icon-calendar absolute ml-4 left-1200 transform -translate-y-1/5 text-xl text-gray-400 cursor-pointer'
                onClick={() => setShowDatePicker(true)}
              />
            </div>
            <button
              type='button'
              onClick={() => handleStartDateChange(newStartDate)}
              className={`ml-2 px-4 py-3 bg-gray-600 text-white rounded-lg focus:outline-none focus:ring focus:border-blue-500 ${changeColorOnHover}`}
              disabled={!newStartDate || isSubmitting}>
              {isSubmitting ? "Changing..." : "Change"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangeStartDate;
