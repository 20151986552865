import React, { useState } from "react";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Select from "react-select";
import Axios from "../../../service/Axios";

const initialState = {
  type: null,
  transactionID: "",
  promoCode: "",
  isSubmitting: false,
  showAlert: { type: "", message: "" },
};

function ChangePromoCode() {
  const [state, setState] = useState(initialState);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #3182ce",
      padding: "4px",
      marginTop: "10px",
    }),
  };

  console.log(state.transactionID);
  console.log(state.type?.value);

  const handleInputChange = (fieldName, value) => {
    setState((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    const endpoint = "/v1/change-promocode";

    try {
      const apiResponse = await Axios.put(
        endpoint,
        {
          typeChange: state.type?.value,
          transactionId: state.transactionID,
          promoCode: state.promoCode,
        }
      );

      if (apiResponse.status === 200) {
        setState(initialState);
        setState((prevState) => ({
          ...prevState,
          showAlert: {
            type: "success",
            message: "Data updated successfully.",
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showAlert: {
            type: "error",
            message: "Failed to update data.",
          },
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        showAlert: {
          type: "error",
          message: "An error occurred. Please ensure that the input is correct and that the rules are followed.",
        },
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  };

  const renderFormFields = () => {
    switch (state.type?.value) {
      case "Add/Replace Promo Code":
        return (
          <>
            <div className='flex justify-center flex-col mb-16'>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mx-4'>
                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='transactionID'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    Transaction ID:
                  </label>
                  <input
                    type='text'
                    id='transactionID'
                    value={state.transactionID}
                    onChange={(e) =>
                      handleInputChange("transactionID", e.target.value)
                    }
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                    placeholder='Enter Transaction ID'
                  />
                </div>

                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='promoCode'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    Promo Code:
                  </label>
                  <input
                    type='text'
                    id='promoCode'
                    value={state.promoCode}
                    onChange={(e) =>
                      handleInputChange("promoCode", e.target.value)
                    }
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                    placeholder='Enter Promo Code'
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "Take Out Promo Code":
        return (
          <div className='mt-8 mb-8 mx-4'>
            <label
              htmlFor='transactionID'
              className='mb-2 block text-sm font-medium text-gray-500'>
              Transaction ID:
            </label>
            <input
              type='text'
              id='transactionID'
              value={state.transactionID}
              onChange={(e) =>
                handleInputChange("transactionID", e.target.value)
              }
              className='py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
              placeholder='Enter Transaction ID'
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='container mx-auto p-4'>
      {state.showAlert.message && (
        <NotificationAlert
          type={state.showAlert.type}
          message={state.showAlert.message}
        />
      )}
      <div className='p-6 mx-10 bg-white shadow-lg rounded-lg'>
        <h2 className='text-2xl font-semibold mb-8 mt-6 mx-4'>
          [ Change Promo Codes ]
        </h2>

        <div className='mt-8 mb-8 mx-4'>
          <label
            htmlFor='type'
            className='mb-2 block text-sm font-medium text-gray-500'>
            Type:
          </label>
          <div className='mb-6'>
            <Select
              id='type'
              value={state.type}
              onChange={(selectedOption) =>
                handleInputChange("type", selectedOption)
              }
              options={[
                { value: "Take Out Promo Code", label: "Take Out Promo Code" },
                {
                  value: "Add/Replace Promo Code",
                  label: "Add/Replace Promo Code",
                },
              ]}
              styles={customStyles}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        </div>

        {renderFormFields()}

        <div className='mb-8 mt-2 mx-4'>
          <button
            onClick={handleSubmit}
            className={`w-full bg-gray-600 text-white py-2 px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring focus:ring-blue-300`}
            disabled={!state.type || !state.transactionID || state.isSubmitting}
            style={{
              boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
              padding: "10px",
              cursor:
                !state.type || !state.transactionID || state.isSubmitting
                  ? "not-allowed"
                  : "pointer",
              opacity:
                !state.type || !state.transactionID || state.isSubmitting
                  ? 0.6
                  : 1,
            }}>
            {state.isSubmitting ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePromoCode;
