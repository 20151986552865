import React from 'react';

function NotificationAlert({ type, message, data }) {
  const alertClasses = `mx-10 mt-4 mb-4 border rounded shadow-lg ${
    type === 'error' ? 'border-red-500 bg-red-100' : 'border-green-500 bg-green-100'
  }`;
  const messageClasses = `text-${type === 'error' ? 'red' : 'green'}-700 font-semibold font-elegant`;

  return (
    <div className={alertClasses}>
      <div className="p-4">
        <p className={messageClasses}>{message}</p>
        {data && (
          <div className="text-gray-600 mt-2">
            <p>Name: {data}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationAlert;
