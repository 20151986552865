import React, { useState, useEffect } from "react";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Select from "react-select";
import Axios from "../../../service/Axios";
import { useTheme } from "../../../context/ThemeContext";

function ChangePackages() {
  const [type, setType] = useState(null);
  const [packageOptions, setPackageOptions] = useState([]);
  const [transactionID, setTransactionID] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+62");
  const [packages, setPackages] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const { isDarkMode } = useTheme();

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #3182ce",
      padding: "4px",
      marginTop: "10px",
    }),
  };

  useEffect(() => {
    const fetchPackageList = async () => {
      try {
        const response = await Axios.get("/v1/package-membership-list");
        const packages = response.data.map((packageMembership) => ({
          value: packageMembership.packageName,
          label: packageMembership.packageName,
        }));
        setPackageOptions(packages);
      } catch (error) {
        console.error("Error fetching package list:", error);
      }
    };

    fetchPackageList();
  }, []);

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "", data: "" });
      }, 15000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const endpoint = "/v1/change-packages";

    try {
      const payload = {
        type_change: type?.value,
        transaction_id: transactionID,
        promo_code: promoCode,
        new_package_name: packages?.value,
        new_package_price: newPrice,
        member_phone: phoneNumber,
      };

      const response = await Axios.put(endpoint, payload);

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Data updated successfully.",
        });
        setType(null);
        setTransactionID("");
        setPackages(null);
        setPromoCode("");
        setPhoneNumber("+62");
        setNewPrice("");
      } else {
        setShowAlert({
          type: "error",
          message: "Something Wrong failed to update data.",
        });
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message:
          "An error occurred. Please ensure that the input is correct and that the rules are followed.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFormFields = () => {
    switch (type?.value) {
      case "Change Package Price":
        return (
          <>
            <div className='flex justify-center flex-col mb-16'>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mx-4'>
                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='transactionID'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    Transaction ID:
                  </label>
                  <input
                    type='text'
                    id='transactionID'
                    value={transactionID}
                    onChange={(e) => setTransactionID(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter Transaction ID'
                  />
                </div>

                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='newPrice'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    New Price:
                  </label>
                  <input
                    type='text'
                    id='newPrice'
                    value={newPrice}
                    onChange={(e) => setNewPrice(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter Promo Code'
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "Change Package Price With Promo Code":
        return (
          <>
            <div className='flex justify-center flex-col mb-14'>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-4'>
                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='transactionID'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    Transaction ID:
                  </label>
                  <input
                    type='text'
                    id='transactionID'
                    value={transactionID}
                    onChange={(e) => setTransactionID(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter Transaction ID'
                  />
                </div>

                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='promoCode'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    Promo Code:
                  </label>
                  <input
                    type='text'
                    id='promoCode'
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter Promo Code'
                  />
                </div>

                <div className='mt-8 flex flex-col justify-between'>
                  <label
                    htmlFor='new price'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    New Price:
                  </label>
                  <input
                    type='text'
                    id='new price'
                    value={newPrice}
                    onChange={(e) => setNewPrice(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter New Price'
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "Change Package Plan":
        return (
          <>
            <div className='flex justify-center flex-col'>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mx-4'>
                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='transactionID'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    Transaction ID:
                  </label>
                  <input
                    type='text'
                    id='transactionID'
                    value={transactionID}
                    onChange={(e) => setTransactionID(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter Transaction ID'
                  />
                </div>

                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='phoneNumber'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    Phone Number:
                  </label>
                  <input
                    type='text'
                    id='phoneNumber'
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter Phone Number'
                  />
                </div>

                <div className='mt-8 flex flex-col justify-between'>
                  <label
                    htmlFor='new price'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    New Price:
                  </label>
                  <input
                    type='text'
                    id='new price'
                    value={newPrice}
                    onChange={(e) => setNewPrice(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter New Price'
                  />
                </div>
              </div>
            </div>
            <div className='mt-8 mb-14 mx-4'>
              <label
                htmlFor='packages'
                className='mb-2 block text-sm font-medium text-gray-500'>
                New Package Name:
              </label>
              <div className='mb-6'>
                <Select
                  id='packages'
                  value={packages}
                  onChange={(selectedOption) => setPackages(selectedOption)}
                  options={packageOptions}
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
            </div>
          </>
        );

      case "Change Package Plan With Promo Code":
        return (
          <>
            <div className='flex justify-center flex-col'>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mx-4'>
                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='transactionID'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    Transaction ID:
                  </label>
                  <input
                    type='text'
                    id='transactionID'
                    value={transactionID}
                    onChange={(e) => setTransactionID(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter Transaction ID'
                  />
                </div>

                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='promoCode'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    Promo Code:
                  </label>
                  <input
                    type='text'
                    id='promoCode'
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter Promo Code'
                  />
                </div>

                <div className='mt-8 flex flex-col justify-between'>
                  <label
                    htmlFor='new price'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    New Price:
                  </label>
                  <input
                    type='text'
                    id='new price'
                    value={newPrice}
                    onChange={(e) => setNewPrice(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter New Price'
                  />
                </div>

                <div className='flex flex-col justify-between mt-6'>
                  <label
                    htmlFor='phoneNumber'
                    className='mb-2 mx-2 block text-sm font-medium text-gray-500'>
                    Phone Number:
                  </label>
                  <input
                    type='text'
                    id='phoneNumber'
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className='py-2 px-4 mx-2 border rounded-lg focus:outline-none focus:ring focus:border-gray-500'
                    placeholder='Enter Phone Number'
                  />
                </div>
              </div>
            </div>
            <div className='mt-8 mb-14 mx-4'>
              <label
                htmlFor='packages'
                className='mb-2 block text-sm font-medium text-gray-500'>
                Packages:
              </label>
              <div className='mb-6'>
                <Select
                  id='packages'
                  value={packages}
                  onChange={(selectedOption) => setPackages(selectedOption)}
                  options={packageOptions}
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className='container mx-auto p-4 scale-95'>
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className={`p-6 mx-10 scale-95 bg-white shadow-lg rounded-lg ${isDarkMode ? 'bg-gradient-to-t from-neutral-50 via-neutral-50 to-neutral-50' : 'bg-gradient-to-b from-zinc-50 via-zinc-50 to-zinc-50'}`}>
        <h2
          className='text-2xl font-semibold mb-8 mt-6 mx-4'
          style={{ textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
          [ Change Packages ]
        </h2>

        <div className='mt-8 mb-8 mx-4'>
          <label
            htmlFor='type'
            className='mb-2 block text-sm font-medium text-gray-500'>
            Type:
          </label>
          <div className='mb-6'>
            <Select
              id='type'
              value={type}
              onChange={(selectedOption) => setType(selectedOption)}
              options={[
                {
                  value: "Change Package Price",
                  label: "Change Package Price",
                },
                {
                  value: "Change Package Price With Promo Code",
                  label: "Change Package Price With Promo Code",
                },
                { value: "Change Package Plan", label: "Change Package Plan" },
                {
                  value: "Change Package Plan With Promo Code",
                  label: "Change Package Plan With Promo Code",
                },
              ]}
              styles={customStyles}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        </div>

        {renderFormFields()}

        <div className='mb-8 mt-2 mx-4'>
          <button
            onClick={handleSubmit}
            className={`w-full bg-gray-600 text-white py-2 px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring focus:ring-gray-300 ${isDarkMode ? 'bg-gradient-to-t from-slate-600 via-slate-600 to-slate-600' : 'bg-gradient-to-b from-slate-700 via-slate-700 to-slate-700'}`}
            disabled={isSubmitting}
            style={{
              boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
              padding: "10px",
            }}>
            {isSubmitting ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePackages;
